<template>
  <div>
    <x-table
      tableType="list"
      :options="options"
      :fixed="false"
      :hideSearchBar="false"
      @search="searchHandle"
      @rowDetail="rowDetail">
      <template slot="guide-content" slot-scope="{data}">
        <div class="x-text-cut">{{ data }} </div>
      </template>
      <template slot="username" slot-scope="scope">
        <b-badge variant="light-primary" class="x-text-cut">
          {{ scope.row.context.username }}
        </b-badge>
      </template>
      <template slot="requestTime" slot-scope="scope">
        <div>{{ new Date(scope.row.requestTime).cxFormat('yyyy-MM-dd hh:mm:ss')  }} </div>
      </template>
      <!-- <template slot="url" slot-scope="scope">
        <div>
          <b-badge variant="success">{{scope.row.httpMethod}}</b-badge>
          {{scope.row.url}}
        </div>
      </template> -->
      <template slot="action" slot-scope="scope">
        <div>
          <b-badge v-if="scope.row.describe" variant="light-primary" class="x-text-cut">
            {{scope.row.describe}}
          </b-badge>
          <b-badge v-else-if="scope.row.action" variant="light-warning" class="x-text-cut">
            {{scope.row.action}}
          </b-badge>
          <b-badge v-else variant="light-danger" class="x-text-cut">
            {{' '}}
          </b-badge>
        </div>
      </template>
      <template slot="context" slot-scope="scope">
        <div>
          <b-badge variant="light-success">
            {{scope.row.context.username}}
          </b-badge>
        </div>
      </template>
      <template slot="responseTime" slot-scope="scope">
        <div class="d-flex align-items-center ">
          <div class="x-dot bg-success"></div>
          {{(scope.row.responseTime || 0) - (scope.row.requestTime || 0)}}ms
        </div>
      </template>
    </x-table>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  query,
} from '@/api/system/log/access'
import {
  VBToggle, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    XTable,
    BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      visible: false,
      access: {},
      options: {
        rowSelectable: false,
        addBtn: false,
        showActions: false,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        // showActions: false,
        actions: [{ action: 'detail', name: '详情', icon: 'EyeIcon' }],
        actionFilter: action => action === 'detail',
        columns: [{
          label: 'IP',
          labelShow: true,
          prop: 'ip',
        }, {
          label: '用户',
          labelShow: true,
          prop: 'username',
        }, {
          label: '日志类型  ',
          labelShow: true,
          prop: 'action',
          editDisable: true,
          searchShow: true,
        }, {
          label: '请求时间',
          labelShow: true,
          prop: 'requestTime',
          type: 'datetimerange',
          editDisable: true,
          searchShow: true,
        }, {
          label: '请求耗时',
          labelShow: true,
          prop: 'responseTime',
          editDisable: true,
          searchShow: false,
        }, {
          label: '请求用户',
          labelShow: true,
          prop: 'context',
          editDisable: true,
          searchShow: false,
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowDetail(data) {
      this.visible = true
      this.access = data
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
